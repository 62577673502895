import * as OM from '@/model';
import store from './store';
export function uuidv4() {
    let base = '' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11;
    return base.replace(/[018]/g, (c) => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}
export const words = ["VERITÀ", "INNOVARE", "MUSICA", "AUTENTICO"];
const letters = ["A", "À", "C", "E", "I", "M", "N", "O", "R", "S", "T", "U", "V", "Z"];
const subfoldersColor = ["AZZURRO", "BIANCO", /*"BLU_CHIARO", "BLU_SCURO",*/ "VERDEACQUA"];
const subfoldersWeight = ["BOLD", "MEDIUM", "THIN"];
const left = ["8vw", "22vw", "36vw", "50vw", "64vw", "75vw"];
const top = ["-10vh", "-35vh", "-45vh", "-60vh", "-70vh"];
const animationClass = ["animazione1", "animazione2", "animazione3", "animazione4", "animazione5"];
const rotateClass = ["rotate1", "rotate2", "rotate3", "rotate4", "rotate5", "rotate6", "rotate7", "rotate8"];
export function getWord() {
    if (store.state.wordIndex == 4)
        store.state.wordIndex = 0;
    var index = store.state.wordIndex;
    store.state.wordIndex++;
    return words[index];
}
export function getRandomLetters(word) {
    var randomL = this.getRandomIntFromInterval(0, word.length);
    return randomL;
}
export function getLetters(word, randomLettersNumber) {
    var tempWord = word.toUpperCase();
    var result = [];
    for (var i = 0; i < tempWord.length; i++) {
        var letter = tempWord[i];
        var letterObj = getLetter(letter, result);
        result.push(letterObj);
    }
    result.push(...randomLetters(randomLettersNumber));
    return result;
}
function randomLetters(number) {
    var random = [];
    var numberOfLetters = number;
    for (var i = 0; i < numberOfLetters; i++) {
        var letter = letters[getRandomIntFromInterval(0, letters.length - 1)];
        var letterObj = getLetter(letter);
        random.push(letterObj);
    }
    return random;
}
function getLetter(letter, letters) {
    var tempRandom = new OM.Letter();
    tempRandom.letter = letter;
    tempRandom.rotateClass = rotateClass[getRandomIntFromInterval(0, rotateClass.length - 1)];
    tempRandom.animationClass = 'animazioneMatta'; // animationClass[getRandomIntFromInterval(0, animationClass.length - 1)];
    if (letters) {
        tempRandom.left = left[letters.length % left.length];
    }
    else {
        tempRandom.left = left[getRandomIntFromInterval(0, left.length - 1)];
    }
    tempRandom.top = top[getRandomIntFromInterval(0, top.length - 1)];
    if (letters) {
        tempRandom.animationSpeed = (5 + (letters.length * 1)) + 's';
    }
    else {
        tempRandom.animationSpeed = getRandomIntFromInterval(10, 20) + 's';
    }
    var url = "";
    url += subfoldersColor[getRandomIntFromInterval(0, subfoldersColor.length - 1)] + "/";
    url += subfoldersWeight[getRandomIntFromInterval(0, subfoldersWeight.length - 1)] + "/";
    url += tempRandom.letter;
    url += ".png";
    tempRandom.imageFile = url;
    if (letters) {
        // letters.forEach(x => {
        //     let xLeft = parseFloat(x.left);
        //     let tempRandomLeft = parseFloat(tempRandom.left);
        //     let leftInPixels = window.innerWidth * xLeft / 100;
        //     let tempRandomLeftInPixels = window.innerWidth * tempRandomLeft / 100;
        //     let centroLetteraX = (leftInPixels + 100) / 2;
        //     let centroLetteraTempRandom = (tempRandomLeftInPixels + 100) / 2;
        //     let finalValue = "";
        //     let loopCounter = 0;
        //     while(centroLetteraX - centroLetteraTempRandom < 100 && loopCounter < 10){
        //         centroLetteraTempRandom += 50;
        //         if(centroLetteraTempRandom + 100 > window.innerWidth){
        //             centroLetteraTempRandom = 50;
        //         }
        //         finalValue = (centroLetteraTempRandom * 100 / window.innerWidth) + 'vw';
        //         loopCounter++;
        //     }
        //     tempRandom.left = finalValue;
        // })
        let tempRandomLeft = parseFloat(tempRandom.left);
        let tempRandomLeftInPixels = window.innerWidth * tempRandomLeft / 100;
        tempRandomLeftInPixels += getRandomIntFromInterval(-50, 50);
        let finalValue = (tempRandomLeftInPixels * 100 / window.innerWidth) + 'vw';
        tempRandom.left = finalValue;
    }
    return tempRandom;
}
export function getRandomIntFromInterval(min, max) {
    var temp = Math.floor(Math.random() * (max - min + 1) + min);
    return temp;
}
export function isInArea(x1, y1, x2, y2, puntoX, puntoY) {
    let isInX;
    if (puntoX >= x1 && puntoX <= x2) {
        isInX = true;
    }
    let isInY;
    if (puntoY >= y1 && puntoY <= y2) {
        isInY = true;
    }
    return isInX && isInY;
}
