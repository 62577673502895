import { createStore } from 'vuex';
import router from './router';
import { words } from './utils';
let _state = {
    wordIndex: 0,
    showSpinner: 0,
    prevFunction: () => { },
    nextFunction: () => { },
    beforeNavCbs: [],
    afterNavCbs: [],
};
setTimeout(() => {
    if (router.currentRoute.value.name == "game") {
        let parolaAttuale = router.currentRoute.value.params.word;
        store.state.wordIndex = words.findIndex(x => x.toLowerCase() == parolaAttuale.toLowerCase()) + 1;
    }
}, 100);
let store = createStore({
    state: _state
});
export default store;
