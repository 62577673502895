import { createApp } from 'vue';
import App from '@/App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/css/style.css';
const app = createApp(App)
    .use(store)
    .use(router);
app.mount('#app');
if (window.cordova) {
    document.addEventListener("deviceready", onDeviceReady, false);
    function onDeviceReady() {
        window.StatusBar.hide();
    }
}
