export class Letter {
    letter;
    imageFile;
    top;
    left;
    animationClass;
    animationSpeed;
    rotateClass;
    constructor() {
        this.letter = "";
        this.top = "";
        this.left = "";
        this.animationClass = "";
        this.animationSpeed = "";
        this.rotateClass = "";
        this.imageFile = "";
    }
}
export class WordLetter {
    letter;
    found;
    constructor() {
        this.letter = "";
        this.found = false;
    }
}
